import React, { useEffect, useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import { graphql, useStaticQuery } from "gatsby"
import "./reviews-page-styles.css"
import PageSelection from "../../components/common/PageSelection"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import useWindowSize from "../../utils/useWindowSize"
import ReviewsGridItems from "../../components/common/ReviewsGridItems"
import HeroSectionWithTitle from "../../components/common/HeroSectionWithTitle"
import { getImage } from "gatsby-plugin-image"

const ReviewsPage = () => {
  const screen = useWindowSize()
  const [gridView, setGridView] = useState(true)
  const [selectedReviews, setSelectedReviews] = useState([])
  const [searchKey, setSearchKey] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("All")
  const [refreshData, setRefreshData] = useState(false)
  const prismicReviewsData = useStaticQuery(graphql`
    query ReviewsPageQuery {
      allPrismicBasePage(filter: { uid: { eq: "reviews" } }) {
        edges {
          node {
            data {
              title
              hero_image {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
      prismicReviews {
        data {
          reviews {
            signature
            image {
              fluid {
                src
              }
            }
            content {
              richText
              text
            }
          }
        }
      }
    }
  `)

  const origReviews = useMemo(() => {
    if (prismicReviewsData.prismicReviews) {
      const tempArray = prismicReviewsData.prismicReviews.data.reviews.map(
        (review, index) => {
          const { signature, content, image } = review
          return {
            id: index,
            signature,
            image: image?.fluid?.src,
            content: content.richText,
            contentText: content.text,
          }
        }
      )

      if (refreshData) {
        setSelectedReviews(tempArray)
        return tempArray
      } else {
        return tempArray.slice(0, 10)
      }
    }
  }, [prismicReviewsData, refreshData])

  const categories = [
    {
      label: "All",
      value: "all",
    },
  ]

  useEffect(() => {
    setSelectedReviews(origReviews)
    setTimeout(() => {
      setRefreshData(true)
    }, 2000)
  }, [])

  useEffect(() => {
    let filtered = [...origReviews]

    if (searchKey) {
      filtered = filtered.filter(review => {
        return (
          review.contentText.toLowerCase().includes(searchKey.toLowerCase()) ||
          review.signature.toLowerCase().includes(searchKey.toLowerCase())
        )
      })
    }

    setSelectedReviews(filtered)
  }, [searchKey])

  const image = getImage(
    prismicReviewsData.allPrismicBasePage?.edges[0].node.data.hero_image
  )

  return (
    <Layout>
      <SEOnew
        title={prismicReviewsData.allPrismicBasePage?.edges[0]?.node.data.title}
      />
      <div className="w-full relative flex flex-col items-center justify-center">
        <HeroSectionWithTitle
          title={
            prismicReviewsData.allPrismicBasePage?.edges[0]?.node.data.title
          }
          description={
            "The joy of creating great homes that live well is realized when our clients let us know how much they enjoy their home and the attention we put into every detail…"
          }
          image={image}
          textLocation="left"
          imagePosition="bottom"
        />
      </div>
      <div className="flex w-full items-center justify-center flex-col pt-2 mb-12 min-h-screen">
        <ReviewsGridItems items={selectedReviews} />
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export default ReviewsPage
