import { PrismicRichText } from "@prismicio/react"
import React from "react"
import htmlSerializer from "../../../utils/htmlSerializer"
import useWindowSize from "../../../utils/useWindowSize"

const ReviewsGridItems = ({ grid = false, items = [] }) => {
  const screen = useWindowSize()
  return (
    <>
      {grid ? (
        <div className="w-full max-w-7xl px-4">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-12 py-12 relative justify-center justify-items-center ">
            {items.map((item, index) => {
              return (
                <div
                  className="w-full h-96 relative flex flex-col reviews-grid reviews-page-item"
                  key={`reviews-${index}-${item.uid}`}
                >
                  <div
                    className="flex flex-grow flex-col px-8 py-12 rounded-3xl review-container "
                    style={{
                      boxShadow: "0px 5px 12px 2px #333333",
                      maxHeight: "90%",
                    }}
                  >
                    <div
                      className="w-full reviews-content-ellipsis"
                      style={{
                        color: "black",
                        overflow: "hidden",
                      }}
                    >
                      {item.image && (
                        <div
                          className="hidden md:flex items-center justify-center ml-4 mt-4 mr-16 mb-8"
                          style={{
                            backgroundImage: `url("${item.image}")`,
                            backgroundSize: "cover",
                            backgroundPosition: "50% 70%",
                            float: "left",
                            top: "50px",
                            borderRadius: "5px",
                            width: "25vh",
                            height: "25vh",
                            maxHeight: "180px",
                            minHeight: "180px",
                            minWidth: "180px",
                            maxWidth: "180px",
                            outline: "#333333 solid 4px",
                            outlineOffset: "4px",
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                      <PrismicRichText
                        field={item.content}
                        components={htmlSerializer}
                      />
                    </div>
                    <div className="pt-4 text-gray-500 text-sm">
                      {item.signature}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <>
          {items.map((item, index) => {
            return (
              <div
                key={`review-${index}`}
                className={`w-full py-8 flex flex-col items-center justify-center ${
                  index % 2 !== 0 ? "bg-site-gray" : "bg-transparent"
                }`}
              >
                <div className="w-full max-w-7xl">
                  <div
                    className={`w-full relative flex flex-row ${
                      index % 2 !== 0 ? "reviews-list" : "reviews-list2"
                    } reviews-page-item `}
                    key={`reviews-${index}-${item.uid}`}
                  >
                    <div className="flex xl:flex-grow flex-col px-8 py-12 review-container overflow-hidden">
                      <div
                        style={{
                          color: "black",
                          overflow: "hidden",
                        }}
                      >
                        {item.image && (
                          <div
                            className="flex items-center justify-center ml-4 mt-4 mr-16 mb-8 "
                            style={{
                              backgroundImage: `url("${item.image}")`,
                              backgroundSize: "cover",
                              backgroundPosition: "50% 70%",
                              float: "left",
                              top: "50px",
                              borderRadius: "5px",
                              width: screen.width <= 640 ? "75vw" : "50vh",
                              height: screen.width <= 640 ? "75vw" : "50vh",
                              outline: `${
                                index % 2 !== 0 ? "white" : "#333333"
                              } solid ${screen.width <= 640 ? "2px" : "5px"}`,
                              outlineOffset: "6px",
                            }}
                          >
                            &nbsp;
                          </div>
                        )}
                        <div className="pt-4">
                          <PrismicRichText
                            field={item.content}
                            components={htmlSerializer}
                          />
                        </div>
                      </div>
                      <div className="pt-4 text-gray-500 text-sm sm:text-base">
                        {item.signature}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}

export default ReviewsGridItems
